import ApiService from '@/services/core/api.service'

const ListService = {
  async getCompanies () {
    return ApiService.get('shared/list/companies')
  },

  async getOrganizations () {
    return ApiService.get('shared/list/organizations')
  },

  async getVehicleTypes () {
    return ApiService.get('shared/list/vehicle-types')
  },

  async getVehicles () {
    return ApiService.get('shared/list/vehicles')
  },

  async getDrivers () {
    return ApiService.get('shared/list/drivers')
  },

  async getAvailableDrivers (filter) {
    return ApiService.get(`shared/list/available-drivers?${filter}`)
  },

  async getAvailableVehicles (filter) {
    return ApiService.get(`shared/list/available-vehicles?${filter}`)
  }
}

export default ListService
