function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/logistics-officer/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const logisticsOfficerRoutes = [
  {
    path: '/logistics-officer/dashboard',
    component: view('Dashboard'),
    name: 'logistics-officer.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/pending-reservations',
    component: view('PendingReservations'),
    name: 'logistics-officer.pending-reservations',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Pending Reservations',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/schedules/vehicle',
    component: view('VehicleSchedules'),
    name: 'logistics-officer.vehicle-schedules',
    meta: {
      breadcrumb: [
        {
          text: 'Schedules'
        },
        {
          text: 'Vehicle',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/schedules/driver',
    component: view('DriverSchedules'),
    name: 'logistics-officer.driver-schedules',
    meta: {
      breadcrumb: [
        {
          text: 'Schedules'
        },
        {
          text: 'Driver',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenance/companies',
    component: view('Companies'),
    name: 'logistics-officer.companies',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Companies',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenance/organizations',
    component: view('Organizations'),
    name: 'logistics-officer.organizations',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Organizations',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenance/vehicle-types',
    component: view('VehicleTypes'),
    name: 'logistics-officer.vehicle-types',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Vehicle Types',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenance/vehicles',
    component: view('Vehicles'),
    name: 'logistics-officer.vehicles',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Vehicles',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenance/drivers',
    component: view('Drivers'),
    name: 'logistics-officer.drivers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Drivers',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/reports/transaction-histories/:status?',
    component: view('TransactionHistories'),
    name: 'logistics-officer.transaction-histories',
    meta: {
      status: 'All',
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Transaction Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/reports/vehicle-schedule-histories',
    component: view('VehicleScheduleHistories'),
    name: 'logistics-officer.vehicle-schedule-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Vehicle Schedule Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/reports/driver-schedule-histories',
    component: view('DriverScheduleHistories'),
    name: 'logistics-officer.driver-schedule-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Driver Schedule Histories',
          active: true
        }
      ]
    }
  }
]

export default logisticsOfficerRoutes
