import ApiService from '@/services/core/api.service'

const ReservationService = {
  async get (query) {
    return ApiService.get(`user/reservations?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/reservations', payload)
  },

  async put (payload) {
    return ApiService.put(`user/reservations/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`user/reservations/${payload.id}`)
  }
}

export default ReservationService
