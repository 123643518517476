import ApiService from '@/services/core/api.service'

const ExportService = {
  async getTransactionHistories (query) {
    return ApiService.get(`logistics-officer/export/transaction-histories?${query}`)
  },
  async getDriverScheduleHistories (query) {
    return ApiService.get(`logistics-officer/export/schedule/driver-histories?${query}`)
  },
  async getVehicleScheduleHistories (query) {
    return ApiService.get(`logistics-officer/export/schedule/vehicle-histories?${query}`)
  }
}

export default ExportService
