import ApiService from '@/services/core/api.service'

const ReportService = {
  async getTransactionHistories (query) {
    return ApiService.get(`logistics-officer/reports/transaction-histories?${query}`)
  },
  async getDriverScheduleHistories (query) {
    return ApiService.get(`logistics-officer/reports/schedule/driver-histories?${query}`)
  },
  async getVehicleScheduleHistories (query) {
    return ApiService.get(`logistics-officer/reports/schedule/vehicle-histories?${query}`)
  }
}

export default ReportService
