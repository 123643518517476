import ApiService from '@/services/core/api.service'

const DriverService = {
  async get (query) {
    return ApiService.get(`logistics-officer/drivers?${query}`)
  },

  async post (payload) {
    return ApiService.post('logistics-officer/drivers', payload)
  },

  async put (payload) {
    return ApiService.put(`logistics-officer/drivers/${payload.id}`, payload)
  }
}

export default DriverService
