import sharedRoutes from './shared-routes'
import adminRoutes from './admin-routes'
import logisticsOfficerRoutes from './logistics-officer-routes'
import userRoutes from './user-routes'

const allRoutes = []

export default allRoutes.concat(
  sharedRoutes,
  adminRoutes,
  logisticsOfficerRoutes,
  userRoutes
)
